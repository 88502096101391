import {Chip, Avatar, Stack, Typography} from '@mui/material';
import React from 'react';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ComputerIcon from '@mui/icons-material/Computer';
import HubIcon from '@mui/icons-material/Hub';
import CustomButton from 'components/CustomerButton/CustomButton';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import {useState } from 'react';
const handleClick = (dest) => {
  window.open(dest, "_blank");
};

export default function ExperienceItem(props) 
{
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Stack onClick={() => toggleIsExpanded()} class = {props.isLeft?"container left-container":"container right-container"}>
      <img onClick={() => toggleIsExpanded()} src={props.image} alt={props.image}/> 
        <Stack class="date">
          <Stack direction="row" spacing="5px" useFlexGap flexWrap="wrap">
            <Chip sx={{borderWidth:"1px", borderColor:"primary.blue", bgcolor:'primary.white', fontSize:{lg:"15px", md:"15px", xs:"2.5vw"}}} avatar={<Avatar sx={{bgcolor:'primary.white'}}><CalendarMonthIcon sx={{color:"primary.blue"}}/></Avatar>} label={props.date} variant="outlined"/> 
            <Chip sx={{borderWidth:"1px", borderColor:"primary.blue", bgcolor:'primary.white', fontSize:{lg:"15px", md:"15px", xs: "2.5vw"}}} avatar={<Avatar sx={{bgcolor:'primary.white'}}><LocationOnOutlinedIcon sx={{color:"primary.blue"}}/></Avatar>} label={props.location} variant="outlined"/>
          </Stack>
        </Stack>
      <Stack direction="row" className={isExpanded?'text-box expanded':"text-box notexpanded"} justifyContent="space-between" overflow="hidden" >
        <Stack direction="column">
          <Stack direction="column">
          <Typography fontWeight="600" fontSize={{lg:"24px", md:"24px",xs:"3vw"}}>{props.company}</Typography>
        <Typography fontWeight="400" fontStyle="italic" fontSize={{lg:"20px", md:"20px",xs:"2.5vw"}}>{props.title}</Typography> 

        </Stack>
       
        <Stack direction="column" marginBottom={0} spacing={{lg:"10px", md:"10px",xs:"10px"}} padding={{lg:"5px", md:"5px",xs:"5px"}} >
            <Stack direction="row" spacing={1}>
            <HubIcon size="small" sx={{paddingTop:"5px", color: "primary.black", fontSize:{lg:"16px", md:"16px", xs: "2.5vw"}}}/>
            <Typography variant='body2' fontSize={{lg:"16px", md:"16px", xs:"2.5vw"}}>{props.skill}</Typography>
            </Stack>
            {
                props.description.map((ds)=>
                {
                  return (
                    <Typography fontSize={{lg:"16px", md:"16px",xs:"2.5vw"}}><ComputerIcon sx={{marginRight:{lg:"5px", md:"5px",xs:"5px"}, fontSize: {lg:"1vw", md: "1.5vw", xs: "2.5vw"}}}/>{ds}</Typography>
                  )
                })
            }
            <Stack direction="row" width="100%" paddingLeft={{lg:"0vw", md:"0vw",xs:"1vw"}} justifyContent="space-between">
            <Stack direction="row" width={{lg:"200px", md:"20vw", xs:"40vw"}} paddingLeft={{lg:"0vw", md:"0vw",xs:"1vw"}} justifyContent="space-between">
              <CustomButton onClick={() => handleClick(props.link)} text="Visit Website"/>
            
            </Stack>   
            </Stack>

            </Stack>                   
        </Stack><Stack  direction="column" justifyContent="end">
            {isExpanded? <VerticalAlignTopIcon/>:<VerticalAlignBottomIcon/>}
          </Stack>
        
    </Stack><Stack class={props.isLeft? "left-container-arrow":"right-container-arrow"}/>
  </Stack>
  );
}